import { useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { useSearchConsole } from '@/contexts/SearchConsoleContext';
import { useAuth } from '@/hooks/useAuth';
import { Loader2 } from 'lucide-react';

export function SearchConsoleAuthStatus() {
  const { user, loading: authLoading } = useAuth();
  const { isAuthorized, isInitializing, authorize } = useSearchConsole();

  const isLoading = authLoading || isInitializing;
  const hasUser = !!user;

  const handleConnect = async () => {
    console.log('[SearchConsoleAuthStatus] Connect button clicked');
    try {
      await authorize();
    } catch (error) {
      console.error('[SearchConsoleAuthStatus] Connection error:', error);
    }
  };

  useEffect(() => {
    console.log('[SearchConsoleAuthStatus] State:', {
      authLoading,
      isInitializing,
      isLoading,
      hasUser,
      isAuthorized
    });
  }, [authLoading, isInitializing, isLoading, hasUser, isAuthorized]);

  if (isLoading) {
    return (
      <div className="flex items-center gap-2">
        <Loader2 className="h-4 w-4 animate-spin" />
        <span>Loading authentication status...</span>
      </div>
    );
  }

  if (!hasUser) {
    return (
      <div className="text-muted-foreground">
        Sign in to connect to Google Search Console
      </div>
    );
  }

  if (!isAuthorized) {
    return (
      <Button onClick={handleConnect} disabled={!hasUser}>
        Connect to Search Console
      </Button>
    );
  }

  return (
    <div className="text-green-600 dark:text-green-500 flex items-center gap-2">
      <span>✓</span>
      <span>Connected to Search Console</span>
    </div>
  );
}
