import { db } from '@/firebase'
import { collection, addDoc, Timestamp } from 'firebase/firestore'
import { ApiKeysService } from './api-keys.service'

interface ScrapedContent {
  url: string
  content: string
  scrapedAt: Date
  projectId: string
}

export class ScrapingService {
  private jinaAuthToken: string | null = null

  async initialize(userId: string) {
    const apiKeysService = ApiKeysService.getInstance()
    const keys = await apiKeysService.getApiKeys(userId)
    
    if (!keys?.jinaAiKey) {
      throw new Error('Jina AI key not found. Please add it in Settings.')
    }
    
    this.jinaAuthToken = keys.jinaAiKey
  }

  async scrapeUrl(url: string): Promise<string> {
    if (!this.jinaAuthToken) {
      throw new Error('Service not initialized. Call initialize() first.')
    }

    try {
      const response = await fetch(`https://r.jina.ai/${url}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.jinaAuthToken}`,
          'X-Return-Format': 'markdown'
        },
      })

      if (!response.ok) {
        throw new Error(`Failed to scrape URL: ${response.statusText}`)
      }

      const content = await response.text()
      if (!content) {
        throw new Error('No content received from scraper')
      }

      return content
    } catch (error) {
      console.error(`Error scraping ${url}:`, error)
      throw error
    }
  }

  async processUrl(url: string, projectId: string): Promise<void> {
    try {
      const content = await this.scrapeUrl(url)

      // Save to Firestore
      const scrapedContent: ScrapedContent = {
        url,
        content,
        scrapedAt: new Date(),
        projectId
      }

      await this.saveScrapedContent(scrapedContent)

      // Create a blob and download it
      const blob = new Blob([content], { type: 'text/markdown' })
      const downloadUrl = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = downloadUrl
      a.download = `${url.replace(/[^a-z0-9]/gi, '_')}.md`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(downloadUrl)
    } catch (error) {
      console.error('Error processing URL:', error)
      throw error
    }
  }

  async saveScrapedContent(content: ScrapedContent): Promise<void> {
    try {
      await addDoc(collection(db, 'scraped-content'), {
        ...content,
        scrapedAt: Timestamp.fromDate(content.scrapedAt)
      })
    } catch (error) {
      console.error('Error saving scraped content:', error)
      throw error
    }
  }
}
