import { createContext, useEffect, useState, ReactNode, useContext } from 'react';
import { User } from 'firebase/auth';
import { firebaseAuth } from '@/lib/services/firebase-auth';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  signOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
  signOut: async () => {},
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('[Auth] Setting up auth state listener...');
    
    // Check if Firebase is initialized
    if (!firebaseAuth.getInstance()) {
      console.error('[Auth] Firebase auth not initialized');
      setLoading(false);
      return;
    }

    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      console.log('[Auth] Auth state changed:', user ? 'User logged in' : 'No user');
      setUser(user);
      setLoading(false);
    });

    // Ensure loading is set to false if auth state listener fails
    setTimeout(() => {
      setLoading((current) => {
        if (current) {
          console.warn('[Auth] Auth state listener timeout - forcing loading to false');
          return false;
        }
        return current;
      });
    }, 5000);

    return () => {
      console.log('[Auth] Cleaning up auth state listener');
      unsubscribe();
    };
  }, []);

  const signOut = async () => {
    try {
      await firebaseAuth.signOut();
      setUser(null);
    } catch (error) {
      console.error('[Auth] Error during sign out:', error);
      throw error;
    }
  };

  const value = {
    user,
    loading,
    signOut,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
