import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/hooks/useAuth';
import { useToast } from '@/hooks/use-toast';
import { searchConsoleClient } from '@/lib/services/search-console/client';

export function SearchConsoleCallback() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { toast } = useToast();
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    const processCallback = async () => {
      try {
        // Get the hash fragment from the URL
        const hash = window.location.hash.substring(1);
        const params = new URLSearchParams(hash);
        const accessToken = params.get('access_token');
        const state = params.get('state');
        const storedState = sessionStorage.getItem('searchConsoleState');
        
        // Clear the stored state
        sessionStorage.removeItem('searchConsoleState');

        if (!accessToken) {
          throw new Error('No access token received');
        }

        if (state !== storedState) {
          throw new Error('Invalid state parameter');
        }

        // Store the token in the client
        await searchConsoleClient.handleTokenResponse({
          access_token: accessToken,
          expires_in: 3600,
          scope: 'https://www.googleapis.com/auth/webmasters.readonly',
          token_type: 'Bearer'
        });

        // Get user info from token for display
        const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${accessToken}` }
        });
        
        if (!response.ok) {
          throw new Error('Failed to get user info');
        }
        
        const userInfo = await response.json();

        toast({
          title: "Successfully connected to Google Search Console",
          description: `Connected account: ${userInfo.email}`,
        });

        // Close the popup and notify the opener
        if (window.opener) {
          window.opener.postMessage({ type: 'gscAuthSuccess', accessToken }, window.location.origin);
          window.close();
        } else {
          // If not in popup, redirect back to the main page
          navigate('/');
        }
      } catch (error: any) {
        console.error('Error processing Search Console callback:', error);
        toast({
          title: "Authentication Failed",
          description: error.message || "Failed to connect to Google Search Console",
          variant: "destructive",
        });
        
        // Notify opener of error and close popup
        if (window.opener) {
          window.opener.postMessage({ type: 'gscAuthError', error: error.message }, window.location.origin);
          window.close();
        } else {
          navigate('/');
        }
      } finally {
        setProcessing(false);
      }
    };

    processCallback();
  }, [navigate, toast]);

  if (processing) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold mb-2">Processing authentication...</h2>
          <p className="text-muted-foreground">Please wait while we complete the setup.</p>
        </div>
      </div>
    );
  }

  return null;
}

export default SearchConsoleCallback;
