import React, { useState } from 'react';
import { X } from 'lucide-react';
import { cn } from '@/lib/utils';

interface DialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  children: React.ReactNode;
  fullScreen?: boolean;
}

interface DialogContentProps {
  children: React.ReactNode;
  className?: string;
  fullScreen?: boolean;
}

interface DialogHeaderProps {
  children: React.ReactNode;
  className?: string;
}

interface DialogTitleProps {
  children: React.ReactNode;
  className?: string;
}

interface DialogDescriptionProps {
  children: React.ReactNode;
  className?: string;
}

interface DialogCloseProps {
  onClick: () => void;
  children?: React.ReactNode;
}

const Dialog: React.FC<DialogProps> = ({ open, onOpenChange, children, fullScreen = false }) => {
  return open ? (
    <div 
      className={`fixed inset-0 z-50 ${fullScreen ? 'bg-white' : 'bg-black/50'} flex items-center justify-center`}
      onClick={() => onOpenChange(false)}
    >
      <div 
        className={`${fullScreen ? 'w-screen h-screen' : 'max-w-lg w-full'} relative`}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  ) : null;
};

const DialogContent: React.FC<DialogContentProps> = ({ children, className, fullScreen }) => {
  return (
    <div 
      className={cn(
        fullScreen ? "h-screen" : "max-h-screen",
        "bg-white rounded-lg p-6 w-full fixed top-0 left-0 z-50 overflow-auto", 
        "transform transition-all duration-300 ease-in-out",
        className
      )}
    >
      {children}
    </div>
  );
};

const DialogHeader: React.FC<DialogHeaderProps> = ({ children, className }) => {
  return (
    <div className={cn("mb-4", className)}>
      {children}
    </div>
  );
};

const DialogTitle: React.FC<DialogTitleProps> = ({ children, className }) => {
  return (
    <h2 className={cn("text-xl font-semibold", className)}>
      {children}
    </h2>
  );
};

const DialogDescription: React.FC<DialogDescriptionProps> = ({ children, className }) => {
  return (
    <p className={cn("text-sm text-gray-600", className)}>
      {children}
    </p>
  );
};

const DialogClose: React.FC<DialogCloseProps> = ({ onClick, children }) => {
  return (
    <button 
      onClick={onClick} 
      className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
    >
      {children || <X className="h-5 w-5" />}
    </button>
  );
};

export { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle, 
  DialogDescription,
  DialogClose
};
