import { useAuth } from '@/hooks/useAuth';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useToast } from '@/hooks/use-toast';
import { useProjects } from '@/contexts/ProjectContext';
import { useSearchConsole } from '@/contexts/SearchConsoleContext';
import { useMemo, useEffect, useState } from 'react';
import { Plus, Loader2 } from 'lucide-react';
import { SearchConsoleAuthStatus } from '@/components/search-console/SearchConsoleAuthStatus';
import { FirestoreService } from '@/lib/services/firestore';
import { ApiKeysService } from '@/lib/services/api-keys.service';
import { Timestamp } from 'firebase/firestore';

export default function Settings() {
  const { user } = useAuth();
  const { toast } = useToast();
  const { properties, refreshProperties } = useSearchConsole();
  const { projects, addProject } = useProjects();
  const [jinaKey, setJinaKey] = useState('');
  const [isLoadingKeys, setIsLoadingKeys] = useState(true);
  const [isSavingKeys, setIsSavingKeys] = useState(false);

  // Load API keys
  useEffect(() => {
    const loadApiKeys = async () => {
      if (!user) return;

      try {
        const apiKeysService = ApiKeysService.getInstance();
        const keys = await apiKeysService.getApiKeys(user.uid);
        if (keys?.jinaAiKey) {
          setJinaKey(keys.jinaAiKey);
        }
      } catch (error) {
        console.error('Error loading API keys:', error);
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to load API keys.",
        });
      } finally {
        setIsLoadingKeys(false);
      }
    };

    loadApiKeys();
  }, [user, toast]);

  // Compute properties not yet added to projects
  const combinedProperties = useMemo(() => {
    const availableProps = properties
      .filter(prop => !projects.some(proj => proj.url === prop.siteUrl.replace(/^sc-domain:/, '')))
      .map(prop => ({
        ...prop,
        cleanSiteUrl: prop.siteUrl.replace(/^sc-domain:/, ''),
        isAvailable: true
      }));

    const existingProjects = projects.map(proj => ({
      siteUrl: proj.url,
      cleanSiteUrl: proj.url,
      permissionLevel: 'siteOwner',
      isAvailable: false,
      createdAt: proj.createdAt
    }));

    return [...availableProps, ...existingProjects]
      .sort((a, b) => a.cleanSiteUrl.localeCompare(b.cleanSiteUrl));
  }, [properties, projects]);

  const handleAddProject = async (property: { siteUrl: string, permissionLevel: string }) => {
    if (!user) return;

    try {
      const cleanSiteUrl = property.siteUrl.replace(/^sc-domain:/, '');
      const url = new URL(cleanSiteUrl.startsWith('http') ? cleanSiteUrl : `https://${cleanSiteUrl}`);
      const businessName = url.hostname
        .replace('www.', '')
        .split('.')[0]
        .replace(/^\w/, c => c.toUpperCase());

      const firestoreService = FirestoreService.getInstance();
      await firestoreService.createSearchConsoleProject(user.uid, {
        name: businessName,
        url: cleanSiteUrl,
      });
      
      toast({
        title: "Project Added",
        description: `${businessName} has been added to your projects.`,
      });

      // Refresh properties to update the UI
      refreshProperties();
    } catch (error) {
      console.error('Error adding project:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to add project. Please check the URL.",
      });
    }
  };

  const handleSaveApiKeys = async () => {
    if (!user) return;

    setIsSavingKeys(true);
    try {
      const apiKeysService = ApiKeysService.getInstance();
      await apiKeysService.updateApiKeys(user.uid, {
        jinaAiKey: jinaKey,
      });

      toast({
        title: "Success",
        description: "API keys have been saved.",
      });
    } catch (error) {
      console.error('Error saving API keys:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to save API keys.",
      });
    } finally {
      setIsSavingKeys(false);
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Settings</h3>
        <p className="text-sm text-muted-foreground">
          Manage your account settings and preferences.
        </p>
      </div>

      <Tabs defaultValue="search-console">
        <TabsList>
          <TabsTrigger value="search-console">Search Console</TabsTrigger>
          <TabsTrigger value="general">General</TabsTrigger>
        </TabsList>

        <TabsContent value="search-console" className="space-y-4">
          <SearchConsoleAuthStatus />
          <Card>
            <CardHeader>
              <CardTitle>Properties</CardTitle>
              <CardDescription>
                Manage your Search Console properties
              </CardDescription>
            </CardHeader>
            <CardContent>
              {combinedProperties.length > 0 ? (
                <ul className="space-y-2">
                  {combinedProperties.map((property) => (
                    <li 
                      key={property.siteUrl} 
                      className="flex items-center justify-between space-x-2 border-b pb-2 last:border-b-0"
                    >
                      <div className="flex items-center space-x-2">
                        <span>{property.cleanSiteUrl}</span>
                        <span className="text-sm text-muted-foreground">
                          ({
                            property.permissionLevel === 'siteOwner' ? 'Owner' :
                            property.permissionLevel === 'siteFullUser' ? 'Administrator' :
                            property.permissionLevel === 'siteUnverifiedUser' ? 'Unverified User' :
                            property.permissionLevel
                          })
                        </span>
                      </div>
                      {property.isAvailable && (
                        <Button 
                          size="sm" 
                          variant="outline"
                          onClick={() => handleAddProject(property)}
                        >
                          <Plus className="mr-1 h-4 w-4" /> Add Project
                        </Button>
                      )}
                      {!property.isAvailable && (
                        <span className="text-xs text-muted-foreground">
                          Added: {new Date(property.createdAt).toLocaleDateString()}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-muted-foreground">
                  No properties available or added.
                </p>
              )}
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="general">
          <Card>
            <CardHeader>
              <CardTitle>API Keys</CardTitle>
              <CardDescription>
                Configure your API keys for various services.
              </CardDescription>
            </CardHeader>
            <CardContent>
              {isLoadingKeys ? (
                <div className="flex items-center space-x-2">
                  <Loader2 className="h-4 w-4 animate-spin" />
                  <span>Loading API keys...</span>
                </div>
              ) : (
                <div className="space-y-4">
                  <div className="space-y-2">
                    <Label htmlFor="jinaAiKey">Jina AI Key</Label>
                    <Input
                      id="jinaAiKey"
                      placeholder="Enter your Jina AI key"
                      type="password"
                      value={jinaKey}
                      onChange={(e) => setJinaKey(e.target.value)}
                    />
                  </div>
                  <Button 
                    onClick={handleSaveApiKeys}
                    disabled={isSavingKeys}
                  >
                    {isSavingKeys ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Saving...
                      </>
                    ) : (
                      'Save API Keys'
                    )}
                  </Button>
                </div>
              )}
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
}
