import React, { useState, useCallback, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Trash2 } from 'lucide-react';
import { fetchSearchConsoleSitemap, SearchConsolePageData } from '@/lib/services/search-console/api';
import { useSearchConsoleAuth } from '@/hooks/search-console/useSearchConsoleAuth';
import { useToast } from '@/hooks/use-toast';
import { Link, useNavigate } from 'react-router-dom';
import { FirestoreService } from '@/lib/services/firestore';
import { useAuth } from '@/hooks/useAuth';
import type { Project } from '@/lib/types/firestore';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '@/firebase';

// Utility function to convert business name to slug
const toSlug = (name: string) => 
  encodeURIComponent(
    name.toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9-]/g, '')
  );

export default function Projects() {
  const { user } = useAuth();
  const { isSearchConsoleAuthorized } = useSearchConsoleAuth('');
  const { toast } = useToast();
  const navigate = useNavigate();
  const [projects, setProjects] = useState<Project[]>([]);

  // State to store Search Console data with local storage persistence
  const [searchConsoleData, setSearchConsoleData] = useState<{
    [domain: string]: SearchConsolePageData
  }>(() => {
    // Initialize from local storage on component mount
    const savedData = localStorage.getItem('searchConsoleData');
    return savedData ? JSON.parse(savedData) : {};
  });

  // Load projects from Firestore
  useEffect(() => {
    const loadProjects = async () => {
      if (!user) return;

      try {
        const firestoreService = FirestoreService.getInstance();
        const userProjects = await firestoreService.getAllProjects(user.uid);
        setProjects(userProjects);
      } catch (error) {
        console.error('Error loading projects:', error);
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to load projects. Please try again.",
        });
      }
    };

    loadProjects();
  }, [user, toast]);

  // Effect to save data to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('searchConsoleData', JSON.stringify(searchConsoleData));
  }, [searchConsoleData]);

  // Fetch sitemap for a specific domain
  const fetchDomainSitemap = useCallback(async (url: string) => {
    try {
      // Check if data is fresh (less than 24 hours old)
      const existingData = searchConsoleData[url];
      const isFresh = existingData && 
        (Date.now() - existingData.fetchedAt) < 24 * 60 * 60 * 1000;

      if (isFresh) {
        return existingData;
      }

      // Fetch new sitemap data
      const sitemapData = await fetchSearchConsoleSitemap(url);

      // Update state and local storage
      const updatedData = {
        ...searchConsoleData,
        [url]: {
          ...sitemapData,
          fetchedAt: Date.now()
        }
      };
      setSearchConsoleData(updatedData);

      return sitemapData;
    } catch (error) {
      console.error('Error fetching sitemap:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch sitemap data. Please try again.",
      });
    }
  }, [searchConsoleData, toast]);

  const deleteProject = async (projectUrl: string) => {
    if (!user) return;

    try {
      const firestoreService = FirestoreService.getInstance();
      
      console.log('Attempting to delete project:', { userId: user.uid, projectUrl });
      
      // Find the project document ID by its URL
      const projects = await firestoreService.getAllProjects(user.uid);
      console.log('All user projects:', projects);
      
      const projectToDelete = projects.find(p => p.url === projectUrl);
      
      if (!projectToDelete) {
        console.error('Project not found:', projectUrl);
        toast({
          title: "Error",
          description: "Project not found.",
          variant: "destructive"
        });
        return;
      }

      // Get the document ID from the projects collection
      const projectsRef = collection(db, `users/${user.uid}/projects`);
      const q = query(projectsRef, where('url', '==', projectUrl));
      const querySnapshot = await getDocs(q);
      
      console.log('Query snapshot:', querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })));
      
      if (querySnapshot.empty) {
        console.error('No project document found for URL:', projectUrl);
        toast({
          title: "Error",
          description: "Project document not found.",
          variant: "destructive"
        });
        return;
      }

      const projectDocId = querySnapshot.docs[0].id;
      console.log('Project document ID:', projectDocId);

      // Delete the project
      await firestoreService.deleteProject(user.uid, projectDocId);

      // Remove the deleted project from local state
      setProjects(prevProjects => prevProjects.filter(project => project.url !== projectUrl));

      toast({
        title: "Project Deleted",
        description: "The project has been successfully removed.",
        variant: "default"
      });
    } catch (error) {
      console.error('Error deleting project:', error);
      toast({
        title: "Error",
        description: `Failed to delete the project: ${error instanceof Error ? error.message : String(error)}`,
        variant: "destructive"
      });
    }
  };

  return (
    <div className="container mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Projects</h1>
        <Button
          onClick={() => navigate('/settings')}
          className="bg-primary hover:bg-primary/90"
        >
          Add Project
        </Button>
      </div>

      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {projects.map((project) => (
          <Card key={project.url} className="hover:shadow-lg transition-shadow">
            <CardHeader>
              <CardTitle className="flex justify-between items-center">
                <span>{project.name}</span>
                <Button 
                  variant="ghost" 
                  size="icon"
                  className="text-destructive hover:text-destructive/90"
                  onClick={() => deleteProject(project.url)}
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                <p className="text-sm text-muted-foreground truncate">
                  {project.url}
                </p>
                <p className="text-sm">
                  Created: {new Date(project.createdAt.seconds * 1000).toLocaleDateString()}
                </p>
                <div className="space-x-2">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => navigate(`/projects/${toSlug(project.name)}`)}
                  >
                    View Details
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => fetchDomainSitemap(project.url)}
                    disabled={!isSearchConsoleAuthorized}
                  >
                    Refresh Data
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}

        {projects.length === 0 && (
          <Card className="col-span-full">
            <CardContent className="flex flex-col items-center justify-center p-6">
              <p className="text-muted-foreground mb-4">No projects found.</p>
              <Button
                onClick={() => navigate('/settings')}
                className="bg-primary hover:bg-primary/90"
              >
                Add Your First Project
              </Button>
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
}
