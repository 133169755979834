import { SearchAnalyticsQuery, SearchAnalyticsResponse, SearchConsoleProperty } from '@/lib/services/search-console/types';
import { searchConsoleClient } from '@/lib/services/search-console/client';

export interface SearchConsolePageData {
  entries: Array<{
    path: string;
  }>;
  totalPages: number;
  fetchedAt: number;
}

export async function fetchSearchConsoleProperties(): Promise<SearchConsoleProperty[]> {
  const accessToken = searchConsoleClient.getAccessToken();
  if (!accessToken) {
    throw new Error('Not authenticated with Google Search Console');
  }

  const response = await fetch(
    "https://www.googleapis.com/webmasters/v3/sites",
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch properties: ${response.statusText}`);
  }

  const data = await response.json();
  return data.siteEntry || [];
}

export async function fetchSearchAnalytics(
  siteUrl: string,
  query: SearchAnalyticsQuery
): Promise<SearchAnalyticsResponse> {
  const accessToken = searchConsoleClient.getAccessToken();
  if (!accessToken) {
    throw new Error('Not authenticated with Google Search Console');
  }

  const response = await fetch(
    `https://www.googleapis.com/webmasters/v3/sites/${encodeURIComponent(siteUrl)}/searchAnalytics/query`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(query),
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch analytics: ${response.statusText}`);
  }

  return response.json();
}

export async function validateToken(): Promise<boolean> {
  try {
    await fetchSearchConsoleProperties();
    return true;
  } catch (error) {
    return false;
  }
}

export const fetchSearchConsoleSitemap = async (siteUrl: string): Promise<SearchConsolePageData> => {
  const accessToken = searchConsoleClient.getAccessToken();
  if (!accessToken) {
    throw new Error('Not authenticated with Google Search Console');
  }

  try {
    // Ensure siteUrl is properly formatted
    const formattedSiteUrl = siteUrl.startsWith('http') ? siteUrl : `https://${siteUrl}`;

    // Use the URL List API to get all indexed URLs
    const response = await fetch(
      `https://www.googleapis.com/webmasters/v3/sites/${encodeURIComponent(formattedSiteUrl)}/urlInspection/index`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch URLs: ${response.statusText}`);
    }

    const data = await response.json();
    const entries = data.urlInspectionResult?.map((result: any) => ({
      path: result.inspectionResult.pageUrl
    })) || [];

    // If no URLs found, at least include the homepage
    if (entries.length === 0) {
      entries.push({
        path: formattedSiteUrl
      });
    }

    return {
      entries,
      totalPages: entries.length,
      fetchedAt: Date.now()
    };
  } catch (error) {
    console.error('Error fetching URLs:', error);
    throw error;
  }
};
