import { useState, useEffect } from 'react'
import { Button } from '@/components/ui/button'
import { useToast } from '@/hooks/use-toast'
import { ScrapingService } from '@/lib/services/scraping.service'
import { Loader2 } from 'lucide-react'
import { fetchSearchConsoleSitemap } from '@/lib/services/search-console/api'
import { searchConsoleClient } from '@/lib/services/search-console/client'
import { useAuth } from '@/hooks/useAuth'
import { useSearchConsole } from '@/contexts/SearchConsoleContext'
import { Card } from '@/components/ui/card'
import { useNavigate } from 'react-router-dom'

interface ScrapingDashboardProps {
  projectId: string
  siteUrl: string
}

export function ScrapingDashboard({ projectId, siteUrl }: ScrapingDashboardProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [urls, setUrls] = useState<string[]>([])
  const { toast } = useToast()
  const { user } = useAuth()
  const { isAuthorized } = useSearchConsole()
  const navigate = useNavigate()
  const [scrapingService] = useState(() => new ScrapingService())
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    const initService = async () => {
      if (!user) return

      try {
        await scrapingService.initialize(user.uid)
        setIsInitialized(true)
      } catch (error) {
        console.error('Error initializing scraping service:', error)
        toast({
          title: "Error",
          description: error instanceof Error ? error.message : "Failed to initialize scraping service",
          variant: "destructive",
        })
      }
    }

    initService()
  }, [user, scrapingService, toast])

  const handleFetchUrls = async () => {
    if (!isAuthorized) {
      toast({
        title: "Authentication Required",
        description: "Please connect to Google Search Console in Settings first.",
        variant: "destructive",
      })
      navigate('/settings')
      return
    }

    setIsLoading(true)
    setProgress(0)
    try {
      const accessToken = searchConsoleClient.getAccessToken()
      if (!accessToken) {
        throw new Error('No access token available')
      }

      const fetchedUrls = await fetchSearchConsoleSitemap(accessToken, siteUrl)
      setUrls(fetchedUrls)
      toast({
        title: "Success",
        description: `Found ${fetchedUrls.length} URLs`,
      })
    } catch (error) {
      console.error('Error fetching URLs:', error)
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to fetch URLs",
        variant: "destructive",
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleScrapeContent = async () => {
    if (!isInitialized) {
      toast({
        title: "Error",
        description: "Scraping service not initialized",
        variant: "destructive",
      })
      return
    }

    if (urls.length === 0) {
      toast({
        title: "No URLs",
        description: "Please fetch URLs first",
        variant: "destructive",
      })
      return
    }

    setIsLoading(true)
    setProgress(0)

    try {
      let completed = 0
      for (const url of urls) {
        try {
          await scrapingService.scrapeAndStore(projectId, url)
          completed++
          setProgress((completed / urls.length) * 100)
        } catch (error) {
          console.error(`Error scraping ${url}:`, error)
        }
      }

      toast({
        title: "Success",
        description: `Scraped ${completed} out of ${urls.length} URLs`,
      })
    } catch (error) {
      console.error('Error during scraping:', error)
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to scrape content",
        variant: "destructive",
      })
    } finally {
      setIsLoading(false)
    }
  }

  if (!isInitialized) {
    return (
      <Card className="p-6">
        <div className="flex items-center space-x-2">
          <Loader2 className="h-4 w-4 animate-spin" />
          <span>Initializing scraping service...</span>
        </div>
      </Card>
    )
  }

  return (
    <div className="space-y-4">
      <div className="flex flex-col space-y-4">
        <Button
          onClick={handleFetchUrls}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Fetching URLs...
            </>
          ) : (
            'Fetch URLs'
          )}
        </Button>

        {urls.length > 0 && (
          <Button
            onClick={handleScrapeContent}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Scraping Content ({Math.round(progress)}%)
              </>
            ) : (
              'Scrape Content'
            )}
          </Button>
        )}
      </div>

      {urls.length > 0 && (
        <div>
          <h3 className="mb-2 text-lg font-medium">Found URLs ({urls.length})</h3>
          <ul className="max-h-60 overflow-y-auto space-y-1">
            {urls.map((url, index) => (
              <li key={index} className="text-sm text-muted-foreground">
                {url}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
