import { loadScript } from '@/lib/utils/script-loader';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '@/firebase';
import { SearchAnalyticsQuery, SearchAnalyticsResponse, SearchConsoleProperty } from './types';
import { SCOPES } from './constants';

interface TokenResponse {
  access_token: string;
  expires_in: number;
  scope: string;
  token_type: string;
  error?: string;
}

interface StoredTokens {
  accessToken: string;
  refreshToken?: string;
  expiryTime: number;
  userId: string;
}

export class SearchConsoleClient {
  private clientId: string | null = null;
  private userId: string | null = null;
  private tokenClient: any = null;
  private initialized = false;
  private accessToken: string | null = null;
  private tokenExpiryTime = 0;

  async initialize(clientId: string, userId: string): Promise<void> {
    console.log('[GSC] Starting initialization...');
    
    if (!clientId) {
      throw new Error('Client ID is required');
    }

    this.clientId = clientId;
    this.userId = userId;

    // Wait for Google Identity Services to be available
    let retries = 0;
    const maxRetries = 10;
    
    while (!(window as any).google?.accounts?.oauth2 && retries < maxRetries) {
      await new Promise(resolve => setTimeout(resolve, 500));
      retries++;
    }

    if (!(window as any).google?.accounts?.oauth2) {
      throw new Error('Google Identity Services failed to load after multiple retries');
    }

    // Initialize token client with callback
    this.tokenClient = (window as any).google.accounts.oauth2.initTokenClient({
      client_id: this.clientId,
      scope: SCOPES.join(' '),
      callback: (tokenResponse: TokenResponse) => {
        console.log('[GSC] Token response received');
        if (tokenResponse.error) {
          console.error('[GSC] Token error:', tokenResponse.error);
          return;
        }
        
        this.handleTokenResponse(tokenResponse);
      },
    });

    this.initialized = true;
    console.log('[GSC] Initialization complete');
  }

  authorize(): void {
    console.log('[GSC] Authorize method called');
    console.log('[GSC] Starting authorization...', {
      initialized: this.initialized,
      userId: this.userId ? 'present' : 'missing',
      tokenClient: this.tokenClient ? 'present' : 'missing'
    });

    if (!this.initialized) {
      throw new Error('Search Console client not initialized. Call initialize() first.');
    }

    if (!this.clientId) {
      throw new Error('Client ID is not set');
    }

    // Generate and store state parameter for security
    const state = Math.random().toString(36).substring(7);
    sessionStorage.setItem('searchConsoleState', state);

    // Construct the OAuth URL
    const scopes = SCOPES.join(' ');
    const redirectUri = `${window.location.origin}/oauth/callback`;
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?` +
      `client_id=${this.clientId}&` +
      `redirect_uri=${encodeURIComponent(redirectUri)}&` +
      `scope=${encodeURIComponent(scopes)}&` +
      `response_type=token&` +
      `state=${state}`;

    console.log('[GSC] OAuth URL constructed:', authUrl);

    // Open the popup window
    const width = 500;
    const height = 600;
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);

    const popup = window.open(
      authUrl,
      'oauth-popup',
      `width=${width},height=${height},left=${left},top=${top}`
    );

    if (!popup) {
      throw new Error('Popup was blocked. Please allow popups for this site.');
    }
  }

  async isAuthorized(): Promise<boolean> {
    console.log('[GSC] Checking authorization status...');
    if (!this.initialized) {
      console.log('[GSC] Not initialized, returning false');
      return false;
    }

    try {
      const hasValidToken = await this.hasValidAccessToken();
      console.log('[GSC] Has valid token:', hasValidToken);
      return hasValidToken;
    } catch (error) {
      console.error('[GSC] Authorization check failed:', error);
      return false;
    }
  }

  async handleTokenResponse(response: TokenResponse): Promise<void> {
    console.log('[GSC] Processing token response');
    if (response.error) {
      throw new Error(`Token error: ${response.error}`);
    }

    this.accessToken = response.access_token;
    this.tokenExpiryTime = Date.now() + (response.expires_in * 1000);
    
    // Store tokens in Firestore
    await this.storeTokens();

    // Dispatch success event
    window.dispatchEvent(new CustomEvent('gscAuthSuccess'));
  }

  async getProperties(): Promise<any[]> {
    if (!this.accessToken) {
      throw new Error('Not authorized. Call authorize() first.');
    }

    const response = await fetch('https://www.googleapis.com/webmasters/v3/sites', {
      headers: {
        'Authorization': `Bearer ${this.accessToken}`
      }
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch properties: ${response.statusText}`);
    }

    const data = await response.json();
    return data.siteEntry || [];
  }

  async clearTokens(): Promise<void> {
    console.log('[GSC] Clearing tokens...');
    this.accessToken = null;
    this.tokenExpiryTime = 0;
    console.log('[GSC] Tokens cleared');
  }

  private async hasValidAccessToken(): Promise<boolean> {
    if (!this.accessToken) {
      console.log('[GSC] No access token present');
      return false;
    }

    if (this.tokenExpiryTime <= Date.now()) {
      console.log('[GSC] Token expired');
      return false;
    }

    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 5000); // 5 second timeout

      const response = await fetch('https://www.googleapis.com/oauth2/v3/tokeninfo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `access_token=${this.accessToken}`,
        signal: controller.signal
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        console.log('[GSC] Token validation failed:', response.status);
        return false;
      }

      const data = await response.json();
      const isValid = data.scope.includes(SCOPES[0]);
      console.log('[GSC] Token validation result:', isValid);
      return isValid;
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        console.error('[GSC] Token validation timed out');
      } else {
        console.error('[GSC] Error validating token:', error);
      }
      return false;
    }
  }

  private async storeTokens() {
    if (!this.userId) {
      console.error('[GSC] User ID is required to store tokens');
      return;
    }

    const tokensRef = doc(db, 'users', this.userId, 'tokens', 'search-console');
    const tokens: StoredTokens = {
      accessToken: this.accessToken as string,
      expiryTime: this.tokenExpiryTime,
      userId: this.userId as string,
    };

    await setDoc(tokensRef, tokens);
  }
}

export const searchConsoleClient = new SearchConsoleClient();
