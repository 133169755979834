import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { searchConsoleClient } from '@/lib/services/search-console/client';
import { SearchConsoleProperty } from '@/lib/services/search-console/types';
import { useToast } from '@/hooks/use-toast';
import { useAuth } from '@/hooks/useAuth';

interface SearchConsoleContextType {
  isAuthorized: boolean;
  isInitializing: boolean;
  isAuthorizing: boolean;
  properties: SearchConsoleProperty[];
  authorize: () => Promise<void>;
  signOut: () => Promise<void>;
  refreshProperties: () => Promise<void>;
  clearTokens: () => Promise<void>;
  error: string | null;
  setError: (error: string | null) => void;
}

const SearchConsoleContext = createContext<SearchConsoleContextType | null>(null);

export function SearchConsoleProvider({ children }: { children: ReactNode }) {
  const { user, loading: authLoading } = useAuth();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [properties, setProperties] = useState<SearchConsoleProperty[]>([]);
  const [error, setError] = useState<string | null>(null);
  const { toast } = useToast();

  const initialize = async () => {
    console.log('[GSC Context] Starting initialization...', { user, authLoading });
    
    try {
      const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
      if (!clientId) throw new Error('Missing Google Client ID');

      if (!user) {
        console.log('[GSC Context] No user, skipping initialization');
        setIsAuthorized(false);
        return;
      }

      console.log('[GSC Context] Initializing client...');
      await searchConsoleClient.initialize(clientId, user.uid);
          
      console.log('[GSC Context] Checking authorization...');
      const authorized = await searchConsoleClient.isAuthorized();
      setIsAuthorized(authorized);

      if (authorized) {
        await refreshProperties();
      }
    } catch (err) {
      console.error('[GSC Context] Initialization error:', err);
      setError(err instanceof Error ? err.message : 'Failed to initialize Search Console');
      setIsAuthorized(false);
    } finally {
      setIsInitializing(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    let timeoutId: number;

    const initializeContext = async () => {
      // Log initial state
      console.log('[GSC Context] Initialize called:', {
        authLoading,
        hasUser: !!user,
        isInitializing
      });

      if (authLoading) {
        console.log('[GSC Context] Auth still loading, waiting...');
        return;
      }

      try {
        await initialize();
      } catch (error) {
        console.error('[GSC Context] Critical initialization error:', error);
        setIsAuthorized(false);
      } finally {
        if (mounted) {
          console.log('[GSC Context] Finalizing initialization');
        }
      }
    };

    // Run initialize immediately if we have a user and auth is not loading
    if (!authLoading) {
      console.log('[GSC Context] Auth ready, running initialize...');
      initializeContext();
    }

    // Set a timeout to force isInitializing to false after 10 seconds
    timeoutId = window.setTimeout(() => {
      if (mounted && isInitializing) {
        console.warn('[GSC Context] Forcing initialization to complete after timeout');
        setIsInitializing(false);
      }
    }, 10000);

    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [user?.uid, authLoading, isInitializing]);

  useEffect(() => {
    const handleAuthSuccess = async () => {
      console.log('[SearchConsoleContext] Auth success event received');
      setIsAuthorized(true);
      setIsAuthorizing(false);
      await refreshProperties();
    };

    const handleAuthError = (event: CustomEvent) => {
      console.error('[SearchConsoleContext] Auth error event received:', event.detail);
      setError(event.detail?.message || 'Failed to authorize with Search Console');
      setIsAuthorized(false);
      setIsAuthorizing(false);
    };

    window.addEventListener('gscAuthSuccess', handleAuthSuccess);
    window.addEventListener('gscAuthError', handleAuthError as EventListener);

    return () => {
      window.removeEventListener('gscAuthSuccess', handleAuthSuccess);
      window.removeEventListener('gscAuthError', handleAuthError as EventListener);
    };
  }, []);

  const authorize = () => {
    console.log('[SearchConsoleContext] Authorize called');
    console.log('[SearchConsoleContext] User state:', { user }); // Log user state
    
    if (!user) {
      const error = 'User must be signed in to authorize';
      console.error('[SearchConsoleContext]', error);
      setError(error);
      return;
    }

    try {
      setIsAuthorizing(true);
      console.log('[SearchConsoleContext] Calling searchConsoleClient.authorize()');
      searchConsoleClient.authorize();
      console.log('[SearchConsoleContext] searchConsoleClient.authorize() called successfully');
    } catch (err) {
      console.error('[SearchConsoleContext] Authorization error:', err);
      setError(err instanceof Error ? err.message : 'Failed to authorize with Search Console');
      setIsAuthorized(false);
    } finally {
      setIsAuthorizing(false);
    }
  };

  const refreshProperties = async () => {
    try {
      const props = await searchConsoleClient.getProperties();
      setProperties(props);
    } catch (error) {
      console.error('[GSC Context] Failed to fetch properties:', error);
      toast({
        title: "Error",
        description: "Failed to fetch Search Console properties",
        variant: "destructive",
      });
    }
  };

  const signOut = async () => {
    await searchConsoleClient.clearTokens();
    setIsAuthorized(false);
    setProperties([]);
  };

  const clearTokens = async () => {
    await searchConsoleClient.clearTokens();
    setIsAuthorized(false);
    setProperties([]);
  };

  const value = {
    isAuthorized,
    isInitializing,
    isAuthorizing,
    properties,
    authorize,
    signOut,
    refreshProperties,
    clearTokens,
    error,
    setError,
  };

  return (
    <SearchConsoleContext.Provider value={value}>
      {children}
    </SearchConsoleContext.Provider>
  );
}

export function useSearchConsole() {
  const context = useContext(SearchConsoleContext);
  if (!context) {
    throw new Error('useSearchConsole must be used within a SearchConsoleProvider');
  }
  return context;
}
