import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
import { Button } from '@/components/ui/button';
import { Textarea } from "@/components/ui/textarea";
import { useToast } from '@/hooks/use-toast';
import { db } from '@/firebase';
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import { MarkdownGeneratorService } from '@/lib/services/markdown-generator';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { useAuth } from '@/hooks/useAuth';
import type { Project } from '@/lib/types/firestore';
import { Loader2 } from 'lucide-react';
import { ScrapingDashboard } from '@/components/scraper/ScrapingDashboard';

// Utility function to convert slug to name
const slugToName = (slug: string) => 
  decodeURIComponent(slug)
    .replace(/-/g, ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export default function ProjectDetail() {
  const { businessSlug } = useParams<{ businessSlug: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { toast } = useToast();
  const [project, setProject] = useState<Project | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // State for markdown generation and viewing
  const [generatedMarkdown, setGeneratedMarkdown] = useState<{
    title: string;
    content: string;
  } | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showMarkdownDialog, setShowMarkdownDialog] = useState(false);
  const [markdownContent, setMarkdownContent] = useState('');

  const markdownGenerator = useMemo(() => new MarkdownGeneratorService(), []);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      if (!user || !businessSlug) {
        setIsLoading(false);
        return;
      }

      try {
        const projectName = slugToName(businessSlug);
        const projectsRef = collection(db, `users/${user.uid}/projects`);
        const q = query(projectsRef, where('name', '==', projectName));

        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          toast({
            title: 'Project not found',
            description: 'The requested project could not be found.',
            variant: 'destructive',
          });
          navigate('/projects');
          return;
        }

        const projectData = {
          id: querySnapshot.docs[0].id,
          ...querySnapshot.docs[0].data(),
        } as Project;

        setProject(projectData);
      } catch (error) {
        console.error('Error fetching project details:', error);
        toast({
          title: 'Error',
          description: 'Failed to load project details.',
          variant: 'destructive',
        });
        navigate('/projects');
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjectDetails();
  }, [user, businessSlug, navigate, toast]);

  const handleGenerateMarkdown = useCallback(async () => {
    if (!project) return;

    setIsGenerating(true);
    try {
      const markdown = await markdownGenerator.generateMarkdown(project);
      setGeneratedMarkdown(markdown);
      setMarkdownContent(markdown.content);
      setShowMarkdownDialog(true);
    } catch (error) {
      console.error('Error generating markdown:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to generate markdown content.',
        variant: 'destructive',
      });
    } finally {
      setIsGenerating(false);
    }
  }, [project, markdownGenerator, toast]);

  const handleSaveMarkdown = useCallback(async () => {
    if (!project || !generatedMarkdown) return;

    try {
      // Save to Firestore
      const markdownDoc = {
        projectId: project.id,
        title: generatedMarkdown.title,
        content: markdownContent,
        createdAt: new Date(),
      };

      await addDoc(collection(db, 'markdown'), markdownDoc);

      toast({
        title: 'Success',
        description: 'Markdown content saved successfully.',
      });

      setShowMarkdownDialog(false);
    } catch (error) {
      console.error('Error saving markdown:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to save markdown content.',
        variant: 'destructive',
      });
    }
  }, [project, generatedMarkdown, markdownContent, toast]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (!project) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-2xl font-bold mb-4">Project not found</h1>
        <Button onClick={() => navigate('/projects')}>Back to Projects</Button>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">{project.name}</h1>
        <Button
          variant="outline"
          onClick={() => navigate('/projects')}
        >
          Back to Projects
        </Button>
      </div>

      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        <Card>
          <CardHeader>
            <CardTitle>Project Overview</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              <p><strong>URL:</strong> {project.url}</p>
              <p><strong>Created:</strong> {new Date(project.createdAt.seconds * 1000).toLocaleDateString()}</p>
            </div>
          </CardContent>
        </Card>

        {project.details && (
          <>
            <Card>
              <CardHeader>
                <CardTitle>Performance Metrics</CardTitle>
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Metric</TableHead>
                      <TableHead>Value</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    <TableRow>
                      <TableCell>Clicks</TableCell>
                      <TableCell>{project.details.clicks}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Impressions</TableCell>
                      <TableCell>{project.details.impressions}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>CTR</TableCell>
                      <TableCell>{(project.details.ctr * 100).toFixed(2)}%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Position</TableCell>
                      <TableCell>{project.details.position.toFixed(1)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Content Generation</CardTitle>
              </CardHeader>
              <CardContent>
                <Button
                  onClick={handleGenerateMarkdown}
                  disabled={isGenerating}
                  className="w-full"
                >
                  {isGenerating ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Generating...
                    </>
                  ) : (
                    'Generate Markdown'
                  )}
                </Button>
              </CardContent>
            </Card>
          </>
        )}

        <Card className="col-span-full">
          <CardHeader>
            <CardTitle>Content Scraping</CardTitle>
          </CardHeader>
          <CardContent>
            {project && (
              <ScrapingDashboard 
                projectId={project.id} 
                siteUrl={project.url}
              />
            )}
          </CardContent>
        </Card>
      </div>

      {/* Markdown Generation Dialog */}
      <Dialog open={showMarkdownDialog} onOpenChange={setShowMarkdownDialog}>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>Generated Markdown</DialogTitle>
            <DialogDescription>
              Review and edit the generated markdown content below.
            </DialogDescription>
          </DialogHeader>
          <div className="mt-4">
            <Textarea
              value={markdownContent}
              onChange={(e) => setMarkdownContent(e.target.value)}
              className="min-h-[300px] font-mono"
            />
          </div>
          <div className="flex justify-end space-x-2 mt-4">
            <Button variant="outline" onClick={() => setShowMarkdownDialog(false)}>
              Cancel
            </Button>
            <Button onClick={handleSaveMarkdown}>Save Markdown</Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
