import axios from 'axios';

export interface MarkdownGenerationResponse {
  markdown: string;
  metadata?: Record<string, any>;
}

export class MarkdownGeneratorService {
  private jinaApiUrl = 'https://r.jina.ai/';
  private authToken: string;

  constructor() {
    this.authToken = import.meta.env.VITE_JINA_AUTH_TOKEN;
    if (!this.authToken) {
      throw new Error('VITE_JINA_AUTH_TOKEN is required');
    }
  }

  async generateMarkdown(project: { url: string }): Promise<{ title: string; content: string }> {
    try {
      // Ensure URL is fully qualified
      const fullUrl = project.url.startsWith('http') ? project.url : `https://${project.url}`;

      // Make request to Jina AI
      const response = await axios.get(this.jinaApiUrl + fullUrl, {
        headers: {
          'Authorization': `Bearer ${this.authToken}`,
          'X-Return-Format': 'markdown',
          'Accept': 'text/markdown'
        },
        transformResponse: [data => data]  // Prevent JSON parsing
      });

      // Validate response status
      if (response.status !== 200) {
        throw new Error(`Unexpected response status: ${response.status}`);
      }

      // Validate and clean markdown
      const content = response.data?.trim();

      if (!content) {
        throw new Error('No markdown content generated');
      }

      return {
        title: `Content for ${project.url}`,
        content
      };
    } catch (error) {
      console.error('Markdown generation error:', error);
      
      if (axios.isAxiosError(error)) {
        if (error.response) {
          throw new Error(`Jina API Error (${error.response.status}): ${error.response.data}`);
        } else if (error.request) {
          throw new Error('Network Error: No response received from Jina API');
        } else {
          throw new Error(`Request Error: ${error.message}`);
        }
      }
      
      throw new Error('Unexpected error during markdown generation');
    }
  }
}
