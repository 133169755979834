import { db } from '@/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

export interface ApiKeys {
  jinaAiKey?: string;
  lastUpdated: Date;
}

export class ApiKeysService {
  private static instance: ApiKeysService;
  private constructor() {}

  static getInstance(): ApiKeysService {
    if (!ApiKeysService.instance) {
      ApiKeysService.instance = new ApiKeysService();
    }
    return ApiKeysService.instance;
  }

  async getApiKeys(userId: string): Promise<ApiKeys | null> {
    try {
      const docRef = doc(db, `users/${userId}/settings/api-keys`);
      const docSnap = await getDoc(docRef);
      
      if (!docSnap.exists()) {
        return null;
      }

      const data = docSnap.data();
      return {
        jinaAiKey: data.jinaAiKey,
        lastUpdated: data.lastUpdated.toDate(),
      };
    } catch (error) {
      console.error('Error fetching API keys:', error);
      throw error;
    }
  }

  async updateApiKeys(userId: string, keys: Partial<ApiKeys>): Promise<void> {
    try {
      const docRef = doc(db, `users/${userId}/settings/api-keys`);
      await setDoc(docRef, {
        ...keys,
        lastUpdated: new Date(),
      }, { merge: true });
    } catch (error) {
      console.error('Error updating API keys:', error);
      throw error;
    }
  }
}
