import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from '@/contexts/AuthContext';
import { ProjectProvider } from '@/contexts/ProjectContext';
import { SearchConsoleProvider } from '@/contexts/SearchConsoleContext';
import ProtectedRoute from '@/components/ProtectedRoute';
import { Toaster } from '@/components/ui/toaster';
import { Layout } from '@/components/layout';
import Login from '@/pages/Login';
import Dashboard from '@/pages/Dashboard';
import Settings from '@/pages/Settings';
import Projects from '@/pages/Projects';
import Content from '@/pages/Content';
import Audit from '@/pages/Audit';
import Performance from '@/pages/Performance';
import Technical from '@/pages/Technical';
import ProjectDetails from '@/pages/ProjectDetail';
import SearchConsoleCallback from '@/components/SearchConsoleCallback';

function App() {
  return (
    <ProjectProvider>
      <SearchConsoleProvider>
        <Router>
          <AuthProvider>
            <Toaster />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/oauth/callback" element={<SearchConsoleCallback />} />
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <Dashboard />
                    </Layout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <Settings />
                    </Layout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/projects"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <Projects />
                    </Layout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/projects/:projectId"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <ProjectDetails />
                    </Layout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/content"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <Content />
                    </Layout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/audit"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <Audit />
                    </Layout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/performance"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <Performance />
                    </Layout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/technical"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <Technical />
                    </Layout>
                  </ProtectedRoute>
                }
              />
            </Routes>
          </AuthProvider>
        </Router>
      </SearchConsoleProvider>
    </ProjectProvider>
  );
}

export default App;
