import { signInWithPopup, signOut, GoogleAuthProvider, User } from 'firebase/auth';
import { auth } from '@/firebase';

const googleProvider = new GoogleAuthProvider();

export const firebaseAuth = {
  // Get the current Firebase auth instance
  getInstance: () => auth,
  
  // Get current user
  getCurrentUser: () => auth.currentUser,
  
  // Sign in with Google
  signInWithGoogle: async () => {
    console.log('[Firebase Auth] Starting Google sign in...');
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log('[Firebase Auth] Sign in successful');
      return result.user;
    } catch (error) {
      console.error('[Firebase Auth] Sign in error:', error);
      throw error;
    }
  },
  
  // Sign out
  signOut: async () => {
    console.log('[Firebase Auth] Starting sign out...');
    try {
      await signOut(auth);
      console.log('[Firebase Auth] Sign out successful');
    } catch (error) {
      console.error('[Firebase Auth] Sign out error:', error);
      throw error;
    }
  },
  
  // Listen to auth state changes
  onAuthStateChanged: (callback: (user: User | null) => void) => {
    console.log('[Firebase Auth] Setting up auth state listener');
    return auth.onAuthStateChanged((user) => {
      console.log('[Firebase Auth] Auth state changed:', user ? 'User present' : 'No user');
      callback(user);
    });
  }
};
